import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Evse, EvseValidationResult} from '@io-elon-common/frontend-api';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {SelectionModel} from '@angular/cdk/collections';
import {EvseService} from '../../service/evse.service';
import {ToastrService} from 'ngx-toastr';
import FixMethodEnum = EvseValidationResult.FixMethodEnum;

import {WhitelistService} from '../../../whitelist/service/whitelist.service';
import {WhitelistEntry} from '@io-elon-common/frontend-api/lib/model/whitelistEntry';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {combineLatest, concat, defer, Observable, of} from 'rxjs';
import {MatAccordion, MatExpansionPanel} from '@angular/material/expansion';

type status = 'idle' | 'success' | 'partial_success' | 'pending' | 'running' | 'failed' | 'config_checking_success' | 'config_checking_failed';

type booleanTrues = '1' | 'true' | 'True' | 'TRUE';
type booleanFalses = '0' | 'false' | 'False' | 'FALSE';
type OperationName = 'übertragen' | 'geprüft' | 'Nicht mehr Ignorieren';

export type BooleanMapValue = {
    true: booleanTrues,
    false: booleanFalses
};

export interface StatusResult {
    status: status,
    displayText: string | undefined
}

export interface EvseValidationStatus {
    evse: Evse,
    whiteList: WhitelistEntry[],
    successfulSetDefaultCount: number,
    statusResult: StatusResult,
    shouldExpand: boolean
}

@Component({
  selector: 'app-bulk-evse-config-validation',
  templateUrl: './bulk-evse-config-validation.component.html',
  styleUrl: './bulk-evse-config-validation.component.scss',
})

export class BulkEvseConfigValidationComponent extends AbstractTableComponent implements OnInit {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

    @ViewChild(MatPaginator,{static:true}) accordion!: MatAccordion
    @ViewChildren("evsePanel") expansionPanels!: QueryList<MatExpansionPanel>;

    public displayedRows!: Observable<EvseValidationStatus[]>;
    public totalRows!: Observable<number>;
    public displayedColumns: string[] = ['name'];
    public evses: EvseValidationStatus[] = [];
    public selection = new SelectionModel<number>(true, []);
    public inProgress: boolean = false;
    public isSetDefaultInProgress: boolean = false;
    public isFinished: boolean = false;
    public currentOperation: OperationName  = 'geprüft';

    private booleanMap: BooleanMapValue[] = [{true: '1', false: '0'}, {true: 'true', false: 'false'}, {true: 'True', false: 'False'}, {true: 'TRUE', false: 'FALSE'}];
    private ids: number[] = [];

    constructor(
        private dialogRef: MatDialogRef<BulkEvseConfigValidationComponent>,
        protected readonly evseService: EvseService,
        private readonly toastr: ToastrService,
        private readonly whitelistService: WhitelistService,
        private readonly systemService: SystemService,
        @Inject(MAT_DIALOG_DATA) public basisId: number) {
        super();
    }

    async ngOnInit(): Promise<void> {
        const supportedEvses = (await this.evseService.getAllPromise()).filter(evse =>
                evse.basis.id === this.basisId &&
                this.systemService.getActorActions(evse).filter(action => action.name === "validateConfiguration").length > 0
        );


        const whiteList = await this.whitelistService.getAllPromise();
        this.evses = supportedEvses.map(evse => {
            return {
                evse: evse,
                whiteList: whiteList.filter(wl => wl.evse === evse.id),
                successfulSetDefaultCount: 0,
                statusResult: {status: 'idle', displayText: ''},
                shouldExpand: false
            }
        });
        this.evses.forEach(e => this.ids.push(e.evse.id))

        const pageEvents: Observable<PageEvent> = this.fromMatPaginator(this.paginator);
        this.displayedRows = of(this.evses).pipe(this.paginateRows(pageEvents));
        this.totalRows = of(this.evses.length);
    }

    private fromMatPaginator(pager: MatPaginator): Observable<PageEvent> {
        return concat(
            defer(() => of({
                pageIndex: pager.pageIndex,
                pageSize: pager.pageSize,
                length: pager.length,
            })),
            pager.page.asObservable()
        );
    }
    private paginateRows<U>(page$: Observable<PageEvent>): (obs$: Observable<U[]>) => Observable<U[]> {
        return (rows$: Observable<U[]>) => combineLatest(
            rows$,
            page$,
            (rows, page) => {
                const startIndex = page.pageIndex * page.pageSize;
                const copy = rows.slice();
                return copy.splice(startIndex, page.pageSize);
            }
        );
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public getSuccessfulCount(): number {
        return this.evses.filter(evse => evse.statusResult.status === 'success' || evse.statusResult.status === 'config_checking_success').length;
    }

    public getValidationResultTotalCount(result: EvseValidationResult[], category: EvseValidationResult.ValidationResultEnum): number {
        return result.filter(rule => rule.validationResult === category).length;
    }

    /** Whether some of the visible rows are selected. Also returns true if no there are no visible rows */
    public areSomeSelected() {
        if (this.ids.length === 0) {
            return true;
        }
        return this.ids.some(id => this.selection.isSelected(id));
    }

    /** Whether all visible rows are selected. */
    public isAllSelected() {
        if (this.ids.length === 0) {
            return false;
        }
        return !this.ids.some(id => !this.selection.isSelected(id));
    }

    /** Selects all visible rows if they are not all selected; otherwise clear selection. */
    public toggleAllRows() {
        this.isAllSelected() ?
            this.ids.forEach(id => this.selection.deselect(id)) :
            this.ids.forEach(id => this.selection.select(id));
    }

    public revalidateAll() {
        this.isSetDefaultInProgress = false;
        this.currentOperation = 'geprüft';
        this.performAction(this.reValidate).then(() => this.isFinished = true);
    }

    public setDefaultToAll() {
        this.isSetDefaultInProgress = true;
        this.currentOperation = 'übertragen';
        this.performAction(this.setDefault).then(() => {
            this.isFinished = true
        });

    }

    public setAllNotIgnore() {
        this.isSetDefaultInProgress = false;
        this.currentOperation = 'Nicht mehr Ignorieren';
        this.performAction(this.setNotIgnore).then(() => this.isFinished = true);
    }

    public handleValidation(evse: Evse) {
        this.evseService.showValidationDialog(evse);
    }

    private async setNotIgnore(data: EvseValidationStatus, context: BulkEvseConfigValidationComponent): Promise<StatusResult> {
        const evse = await context.evseService.getPromise(data.evse.id, false);

        if (context.getValidationResultTotalCount(evse.liveData.validationResult, 'WHITELIST_VALID') >= 0
            || context.getValidationResultTotalCount(evse.liveData.validationResult, 'WHITELIST_INVALID') >= 0
            || context.getValidationResultTotalCount(evse.liveData.validationResult, 'WHITELIST_ERROR') >= 0) {
            data.shouldExpand = true;
        }

        for (const wl of data.whiteList) {
            await context.whitelistService.delete(wl.id, {});
        }

        data.whiteList = [];

        if (evse) {
            const idx = context.evses.findIndex(val => val.evse.id === evse.id);
            context.evses[idx].evse = evse;
        }

        return new Promise<StatusResult>((resolve) => {
            resolve({
                status: 'success',
                displayText: 'Success'
            });
        });
    }

    private shouldSetDefaultValue(rule: EvseValidationResult): boolean {
        let targetVal: string | undefined;
        switch (rule.fixMethod) {
            case FixMethodEnum.SetToTrue:
                targetVal = this.getTrueVal(rule.validationVal);
                break;
            case FixMethodEnum.SetToFalse:
                targetVal = this.getFalseVal(rule.validationVal);
                break;
            default:
                targetVal = rule.ruleDefaultVal;
        }

        if (targetVal) {
            return rule.validationResult === 'INVALID' ||
                rule.validationResult === 'VALIDATION_ERROR';
        }
        return false;
    }

    private async performAction(func: (arg0: EvseValidationStatus, arg1: BulkEvseConfigValidationComponent) => Promise<StatusResult | undefined>) {
        this.inProgress = true;
        this.evses.forEach(evseStatus => {
            if (this.selection.isSelected(evseStatus.evse.id)) {
                evseStatus.statusResult.status = 'pending';
                evseStatus.successfulSetDefaultCount = 0;
            } else {
                evseStatus.statusResult.status = 'idle';
            }
            evseStatus.shouldExpand = false;
        });

        for (const data of this.evses) {
            if (this.selection.isSelected(data.evse.id)) {
                try {
                    data.statusResult.status = 'running';
                    const ret = await func(data, this);
                    if (ret) {
                        data.statusResult = ret;
                    } else {
                        data.statusResult.status = 'failed';
                    }

                } catch (e) {
                    data.statusResult.status = 'failed';
                }
            }
        }
        this.inProgress = false;
    }

    private async setDefault(data: EvseValidationStatus, context: BulkEvseConfigValidationComponent): Promise<StatusResult> {
        let ret;
        let totalCount = 0;
        for (const rule of data.evse.liveData.validationResult) {
            if (context.shouldSetDefaultValue(rule)) {
                totalCount++;
                try {
                    let targetVal: string | undefined;
                    switch (rule.fixMethod) {
                        case FixMethodEnum.SetToTrue:
                            targetVal = context.getTrueVal(rule.validationVal);
                            break;
                        case FixMethodEnum.SetToFalse:
                            targetVal = context.getFalseVal(rule.validationVal);
                            break;
                        default:
                            targetVal = rule.ruleDefaultVal;
                    }

                    if (targetVal) {
                        ret = context.evseService.executeAction(data.evse.id, {
                            name: "setSpecificData",
                            arguments: [{
                                name: "key",
                                value: rule.ruleKey
                            }, {
                                name: "value",
                                value: targetVal
                            }]
                        });
                        const result = await ret;
                        await context.reValidate(data, context);

                        if (result.success) {
                            data.successfulSetDefaultCount++;
                        }
                    }
                } catch (e) {
                    return new Promise<StatusResult>((resolve) => {
                        resolve({
                            status: 'failed',
                            displayText: 'Failed'
                        });
                    });
                }
            }
        }

        let stat: StatusResult = {status: 'failed', displayText: 'Failed'};
        if (data.successfulSetDefaultCount === totalCount) {
            stat.status = 'success';
            stat.displayText = 'Success';
        } else if (data.successfulSetDefaultCount > 0) {
            stat.status = 'partial_success';
            stat.displayText = 'Partial Success';
        }

        return new Promise<StatusResult>((resolve) => {
            resolve(stat);
        });
    }

    private async reValidate(data: EvseValidationStatus, context: BulkEvseConfigValidationComponent): Promise<StatusResult> {
        const ret = await context.evseService.executeAction(data.evse.id, {
            name: "validateConfiguration",
            arguments: []
        });

        const evse = await context.evseService.getPromise(data.evse.id, false);

        if (context.getValidationResultTotalCount(evse.liveData.validationResult, 'INVALID') >=0
            || context.getValidationResultTotalCount(evse.liveData.validationResult, 'VALIDATION_ERROR') >= 0) {
            data.shouldExpand = true;
        }


        if (evse) {
            const idx= context.evses.findIndex(val=>val.evse.id === evse.id);
            context.evses[idx].evse = evse;
        }



        return new Promise<StatusResult>((resolve) => {
            resolve({
                    status: ret && ret.success ? 'config_checking_success' : 'config_checking_failed',
                    displayText: ret.value
            });
        });
    }

    private getTrueVal(val: string): string | undefined {
        const tv = this.booleanMap.find(b => b.false === val);
        return tv ? tv.true : undefined;
    }

    private getFalseVal(val: string): string | undefined {
        const fv = this.booleanMap.find(b => b.true === val);
        return fv ? fv.false : undefined;
    }
}
