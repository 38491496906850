import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    ExecuteVehicleAddOrUpdate,
    FleetHierarchy,
    FreeToMoveDataSource,
    Rfid,
    RioAssetData,
    STecAccount,
    TeslaAccount,
    TeslaAccountVehicle,
    VehicleMini,
} from '@io-elon-common/frontend-api';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {DongleService} from '../../../dongle/service/dongle.service';
import {map, startWith} from 'rxjs/operators';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {TeslaAccountService} from '../../../tesla/service/tesla-account.service';
import {RfidService} from '../../../rfid/service/rfid.service';
import {FreeToMoveService} from '../../../free-to-move/service/free-to-move.service';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormBuilder,
    FormControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {VehicleService} from '../../service/vehicle.service';
import {STecAccountService} from '../../../s-tec-accounts/service/s-tec-account.service';
import {AuthService} from '../../../../shared/guards/auth.service';
import {CustomerService} from '../../../../services/api-handlers/customer.service';
import {FleetHierarchyFleet} from '@io-elon-common/frontend-api/lib/model/fleetHierarchyFleet';
import {FleetService} from '../../service/fleet.service';
import {DialogType} from '../../../../shared/components/help-box/dialogType';
import {InputDataValidationService} from '../../../../services/api-handlers/input-data-validation.service';
import {VehicleTypeService} from '../../../vehicle-types/services/vehicle-type.service';
import {VehicleType} from '@io-elon-common/frontend-api/lib/model/models';

const TESLA_API_TYPE = "Tesla API";
const MERCEDES_API_TYPE = "Mercedes Benz API";
const DONGLE_TYPE = "IO-ELON Dongle";
const FREE_TO_MOVE_TYPE = "Free2Move";
const TRONITY_TYPE = "Tronity";
const RIO_TYPE = "Rio";
const S_TEC_TYPE = "S-Tec"
const KUANTIC_TYPE = "Kuantic";
const WEBFLEET_TYPE = "WebFleet";
const NO_SOURCE = "Keine Datenquelle";

interface StateGroupManufacturer {
    //For Autocomplete
    manufacturer: string;
    vehicles: Array<VehicleType>
}


@Component({
    selector: "app-edit-vehicle",
    templateUrl: "./edit-vehicle-dialog.component.html",
    styleUrls: ["./edit-vehicle-dialog.component.scss"],
})
export class EditVehicleDialogComponent
    implements OnInit, OnDestroy, IEditForm<ExecuteVehicleAddOrUpdate>
{
    @Input()
    public edit!: boolean;
    @Input()
    public data!: ExecuteVehicleAddOrUpdate;
    public vehicleTypes!: Promise<number[]>;
    public dataSources: Observable<{ id: number; name: string; info: string }[] | undefined> | null = null;
    public teslaAccounts!: BehaviorSubject<TeslaAccount[] | undefined>;
    public freeToMoves!: BehaviorSubject<FreeToMoveDataSource[] | undefined>;
    public sTecAccounts!: BehaviorSubject<STecAccount[] | undefined>;
    public dataSourceType = "";
    public teslaVehicles?: BehaviorSubject<TeslaAccountVehicle[] | undefined>;
    public rfids!: Observable<Rfid[] | undefined>;
    public minAmp!: number; //= 6;
    public maxAmp!: number; //= 32;
    public vehicleList: VehicleType[] = []
    public nameAndImagesList: StateGroupManufacturer[] = [];
    public fc!: FormControl<string | undefined | null>
    public manufacturerGroupOptions: Observable<StateGroupManufacturer[]>| undefined;
    public name = new FormControl("", [Validators.required]);
    public checkMaxAmps = new FormControl(-1, [this.checkMaxAmpsVal()]);
    public checkMinAmps = new FormControl({ name: new FormControl(-1) }, [
        Validators.required,
        this.checkMinAmpsVal(),
    ]);
    public checkTargetSoc = new FormControl({ name: new FormControl(-1) }, [
        Validators.required,
        this.checkTargetSocVal(),
    ]);
    public rioVin?: string;
    public rioEnterAssetId?: boolean;
    public rioResult?: { vin: string; list: Array<RioAssetData> };
    public changeFleet: boolean = false;
    public selectableFleet: FleetHierarchyFleet[] = [];
    public readonly DialogType = DialogType;
    public kuanticVinCtrl: FormControl<string | null | undefined> = new FormControl(null, {updateOn: 'blur'});
    public vehicleTypeSubscription!: Subscription;

    constructor(
        private readonly systemService: SystemService,
        private readonly dongleService: DongleService,
        private readonly teslaAccountService: TeslaAccountService,
        private readonly rfidService: RfidService,
        private readonly freeToMoveService: FreeToMoveService,
        private readonly vehicleService: VehicleService,
        private readonly formBuilder: FormBuilder,
        private readonly sTecService: STecAccountService,
        private readonly customerService: CustomerService,
        private readonly authService: AuthService,
        private readonly fleetService: FleetService,
        private inputDataValidationService: InputDataValidationService,
        private readonly vehicleTypeService: VehicleTypeService
    ) {}

    private static formatVehicle(v?: VehicleMini): string {
        if (!v) {
            return "Keinem Fahrzeug zugeordnet";
        }
        return v.name || v.numberPlate || v.localId || v.id + "";
    }

    public async ngOnInit(): Promise<void> {
        this.teslaAccounts = this.teslaAccountService.getAll();
        this.freeToMoves = this.freeToMoveService.getAll();
        this.sTecAccounts = this.sTecService.getAll();
        this.rfids = this.rfidService.getAll();
        if (this.data.connection.dongle) {
            this.dataSourceType = DONGLE_TYPE;
        } else if (this.data.connection.tesla) {
            this.dataSourceType = TESLA_API_TYPE;
        } else if (this.data.connection.free2move) {
            this.dataSourceType = FREE_TO_MOVE_TYPE;
        } else if (this.data.connection.mercedes) {
            this.dataSourceType = MERCEDES_API_TYPE;
        } else if (this.data.connection.tronity) {
            this.dataSourceType = TRONITY_TYPE;
        } else if (this.data.connection.rio) {
            this.dataSourceType = RIO_TYPE;
        } else if (this.data.connection.sTec) {
            this.dataSourceType = S_TEC_TYPE;
        } else if (this.data.connection.kuantic) {
            this.dataSourceType = KUANTIC_TYPE;
        } else if(this.data.connection.webfleet) {
            this.dataSourceType = WEBFLEET_TYPE;
        }
        else {
            this.dataSourceType = NO_SOURCE;
        }
        this.fc = new FormControl(undefined)
        this.manufacturerGroupOptions = this.fc.valueChanges.pipe(
            startWith(""),
            map((value) => this.filterGroup(value || ""))
        );
        this.vehicleTypeSubscription = this.vehicleTypeService.getVehicleTypes().subscribe(value => {
            if(value) {
                this.setUpVehicleTypes(value)
                this.fc.setValue(value.find(value1 => value1.id === this.data.vehicleTypeId)?.modelName)
            }
        })
        this.onTypeChange(this.dataSourceType);

        this.minAmp = this.vehicleList.find(val => val.id === this.data.vehicleTypeId)?.minAmps!;
        this.maxAmp = this.vehicleList.find(val => val.id === this.data.vehicleTypeId)?.maxAmps!;

        const fleetHierarchy = this.customerService.getFleetHierarchy(this.authService.user?.customerId || -1).getValue();
        if (fleetHierarchy) {
            const fleets = this.findOtherFleets(fleetHierarchy, this.data.fleetId);
            if (fleets) {
                const allFleets = await this.fleetService.getAllPromise();
                fleets.forEach(fleet => {
                    const shouldBeSelectable = fleet.id === this.data.fleetId || allFleets.some(af => af.id === fleet.id && af.canEdit);
                    if (shouldBeSelectable) {
                        this.selectableFleet.push(fleet);
                    }
                });
            } else {
                console.error("unable to find fleets");
            }
        } else {
            console.error("unable to find fleet Hierarchy");
        }

        this.kuanticVinCtrl.setValue(this.data.connection.kuantic?.vin);
        this.kuanticVinCtrl.setAsyncValidators(this.validateKuanticVinAlreadyExists());
    }

    private async setUpVehicleTypes(vehicleList: VehicleType[]) {
        this.vehicleList = vehicleList.filter(value => !value.deprecated);
        this.vehicleTypes = Promise.resolve(this.vehicleList.map(vehicle => vehicle.id))
        const uniqueManufacturer = Array.from(
            new Set(
                this.vehicleList.map((vehicle) => {
                    return vehicle.manufacturer;
                }).sort((a, b) => a.localeCompare(b))
            )
        );
        for (const manufacturer of uniqueManufacturer) {
            this.nameAndImagesList.push({
                manufacturer: manufacturer,
                vehicles: this.vehicleList.filter(vObj => vObj.manufacturer === manufacturer)
            });
        }
    }

    private filterGroup(value: string): StateGroupManufacturer[] {
        if (value) {
            return this.nameAndImagesList.map(group => ({
                    manufacturer: group.manufacturer,
                    vehicles: group.vehicles.filter(v => v.modelName.toLowerCase().includes(value.toLowerCase()) || v.manufacturer.toLowerCase().includes(value.toLowerCase()))
                }))
                .filter((group) => group.vehicles.length > 0);
        }
        return this.nameAndImagesList;
    }
    public onVehicleTypeChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        const vehicleType = this.vehicleList.find(val => val.modelName === value);
        if(vehicleType) {
            this.minAmp = vehicleType.minAmps ?? this.minAmp;
            this.maxAmp = vehicleType.maxAmps ?? this.maxAmp;
            this.data.confMinAmps = this.minAmp;
            this.data.confMaxAmps = this.maxAmp;
            this.data.confMinAmps = vehicleType.minAmps ?? this.data.confMinAmps;
            this.data.confMaxAmps =vehicleType.maxAmps ?? this.data.confMaxAmps;
            this.data.vehicleTypeId = vehicleType.id ?? this.data.vehicleTypeId;
        }
    }

    public onTypeChange(newType: string) {
        switch (newType) {
            case DONGLE_TYPE:
                this.data.connection.dongle = this.data.connection.dongle || {
                    dataSourceId: -1
                };
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.kuantic = undefined;
                this.data.connection.webfleet = undefined;

                this.dataSources = this.dongleService.getAll().pipe(
                    map(dongles => dongles?.map(d => {
                                return {
                                    id: d.id,
                                    name: "Dongle " + d.dongleId,
                                    info: "Aktuell: " + EditVehicleDialogComponent.formatVehicle(d.vehicle),
                                };
                            }).sort()
                    )
                );
                break;
            case TESLA_API_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = this.data.connection.tesla || {
                    teslaAccountId: -1,
                    teslaVehicleId: ""
                };
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.kuantic = undefined;
                this.data.connection.webfleet = undefined;

                this.dataSources = this.teslaAccountService.getAll().pipe(
                    map(teslaApi => {
                        const ret = teslaApi?.map(t => {
                                return {
                                    id: t.id,
                                    name: t.name,
                                    info: ""
                                };
                            })
                            .sort();
                        this.onTeslaAccountChange();
                        return ret;
                    })
                );
                break;
            case MERCEDES_API_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = this.data.connection.mercedes || {
                    vin: ""
                } as any;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.kuantic = undefined;
                this.data.connection.webfleet = undefined;
                break;
            case FREE_TO_MOVE_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = this.data.connection.free2move || {
                    dataSourceId: null
                } as any;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.kuantic = undefined;
                this.data.connection.webfleet = undefined;
                break;
            case TRONITY_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = {};
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.kuantic = undefined;
                this.data.connection.webfleet = undefined;
                break;
            case RIO_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = this.data.connection.rio || { rioAssetId: "" }
                this.data.connection.sTec = undefined;
                this.data.connection.webfleet = undefined;
                break;
            case S_TEC_TYPE:
                this.data.connection = {
                    sTec:this.data.connection.sTec || { accountId: -1, troId: ""}
                };
                break;
            case NO_SOURCE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.kuantic = undefined;
                this.data.connection.webfleet = undefined;
                break;
            case KUANTIC_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.webfleet = undefined;
                this.data.connection.kuantic = this.data.connection.kuantic || {
                    vin: ""
                } as any;
                break;
            case WEBFLEET_TYPE:
                this.data.connection.dongle = undefined;
                this.data.connection.tesla = undefined;
                this.data.connection.mercedes = undefined;
                this.data.connection.free2move = undefined;
                this.data.connection.tronity = undefined;
                this.data.connection.rio = undefined;
                this.data.connection.sTec = undefined;
                this.data.connection.webfleet = this.data.connection.webfleet || {
                    accountId: -1,
                    webfleetId: ""
                }
                this.data.connection.kuantic = undefined;

                break;
            default:
                console.warn("Unknown Type: " + newType);
                this.dataSources = null;
        }
    }

    public onTeslaAccountChange() {
        if (this.data.connection.tesla && this.data.connection.tesla.teslaAccountId !== -1) {
            this.teslaVehicles = this.teslaAccountService.getVehicleList(
                this.data.connection.tesla.teslaAccountId
            );
        } else {
            this.teslaVehicles = undefined;
        }
    }
    public checkMinAmpsVal(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value !== null && control.value < this.minAmp) {
                return { result: true };
            }
            if (this.data != null) {
                if (control.value !== null && control.value > this.data.confMaxAmps) {
                    return { greaterThanMaxAmp: true };
                }
            }
            return null;
        };
    }

    public checkMaxAmpsVal(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value !== null && control.value > this.maxAmp) {
                return { result: true };
            }
            if (this.data != null) {
                if (control.value != null && control.value < this.data.confMinAmps) {
                    return { smallerThanMinAmp: true };
                }
            }
            return null;
        };
    }

    public checkTargetSocVal(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value !== null && (control.value < 1 || control.value > 100)) {
                return { invalidValue: true };
            }
            return null;
        };
    }

    validate(): string[] {
        let err: string[] = [];
        if (!this.data.name) {
            err.push("- Name.<br>");
        }
        if (!this.data.vehicleTypeId) {
            err.push("- Typ.<br>");
        }
        if (!this.data.localId) {
            err.push("- Fahrzeugnummer.<br>");
        }

        if (this.isAmpsPresent()) {
            this.data.confMinAmps = 0;
            this.data.confMaxAmps = 0;
        } else {
            if (!this.data.confMinAmps) {
                err.push("- Minimaler Ladestrom.<br>");
            }
            if (this.checkMinAmps.errors?.greaterThanMaxAmp) {
                err.push("- MinAmps Wert größer als Maximum.<br>");
            }
            if (this.checkMaxAmps.errors?.smallerThanMinAmp) {
                err.push("- MaxAmps Wert kleiner als Minimum.<br>");
            }
            if (this.checkMinAmps.errors?.result) {
                err.push("- Minimaler Strom ist zu klein.<br>");
            }
            if (!this.data.confMaxAmps) {
                err.push("- Maximaler Ladestrom.<br>");
            }
            if (this.checkMaxAmps.errors?.result) {
                err.push("- Maximaler Strom ist zu groß.<br>");
            }
        }

        if (!this.data.defaultTargetSoc || !Number(this.data.defaultTargetSoc) || this.data.defaultTargetSoc < 0 || this.data.defaultTargetSoc > 100) {
            err.push("- Default Ladestand muss zwischen 1 und 100 liegen.<br>");
        }
        if (this.data.connection.rio && !this.data.connection.rio.rioAssetId) {
            err.push("- Es muss eine RIO Asset ID eingetragen werden.<br>")
        }
        if (this.data.connection.sTec && this.data.connection.sTec.accountId === -1) {
            err.push("- Das S-Tec-Konto ist jetzt ausgewählt.<br>")
        }
        if(this.data.connection.webfleet && this.data.connection.webfleet.accountId === -1 && this.data.connection.webfleet.webfleetId.length > 0) {
            err.push("- Es muss ein Webfleet Account ausgewählt werden und ein zugehöriges Fahrzeug.<br>")
        }
        if (this.data.connection.kuantic) {
            if (this.kuanticVinCtrl.getRawValue() !== undefined && this.kuanticVinCtrl.getRawValue() !== null) {
                this.data.connection.kuantic.vin = this.kuanticVinCtrl.getRawValue() as string;
            } else {
                err.push("- Ungültige VIN-Nummer.<br>")
            }
        }

        if (err.length > 0) {
            err = ["Nicht ausgefüllt:<br>"].concat(err);
        }
        return err;
    }

    public async rioSearch(): Promise<void> {
        const vin = this.rioVin;
        if(vin) {
            this.rioResult = {
                vin: vin,
                list: (await this.vehicleService.getRioAssetsByVin(vin)).list
            }
        }
    }

    public isAmpsPresent() {
        return this.minAmp === 0 && this.maxAmp === 0;
    }

    private findOtherFleets(fh: FleetHierarchy, fleetId: number): FleetHierarchyFleet[] | undefined {
        if (fh.fleets.some(f => f.id === fleetId)) {
            return fh.fleets;
        }
        for (const sfh of fh.subCustomers) {
            const cId = this.findOtherFleets(sfh, fleetId);
            if (cId) {
                return cId;
            }
        }
        return undefined;
    }

    private validateKuanticVinAlreadyExists(): AsyncValidatorFn {
        return async (control: AbstractControl): Promise<ValidationErrors | null> => {
            const value = this.kuanticVinCtrl.getRawValue();
            if (value === this.data.connection.kuantic?.vin) {
                return null;
            } else if (value === undefined || value === null) {
                return {errorText: "Ungültige VIN"};
            } else {
                const result = await this.inputDataValidationService.isInputDataExists({
                    key: "KuanticVin",
                    value: value
                });
                if (result.value) {
                    return {errorText: "VIN bereits verwendet"};
                }
                return null;
            }
        }
    }

    ngOnDestroy(): void {
        this.vehicleTypeSubscription.unsubscribe();
    }
}
