<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row justify-content-between align-items-stretch gap-1">

        <!-- Fahrzeug / Fahrer -->
        <div class="d-flex flex-column justify-content-between">
            <h3 class="flex-grow-0">Fahrzeug / Fahrer</h3>
            <div class="d-flex flex-column justify-content-end flex-grow-0">
                <mat-form-field appearance="fill">
                    <mat-label>Fahrzeug</mat-label>
                    <mat-select [(value)]="vehicle">
                        <mat-option *ngFor="let v of vehicles" [value]="v">{{v.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Nutzer</mat-label>
                    <mat-select [(value)]="data.driverId">
                        <mat-option *ngFor="let u of users" [value]="u.id">{{u.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- Distanz -->
        <div class="d-flex flex-column justify-content-between">
            <h3>Distanz</h3>
            <div class="d-flex flex-column justify-content-end">
                <mat-form-field appearance="fill" [color]="isValidUserSocDistance ? 'primary' : 'warn'" [matTooltip]="getDistanceToolTip()">
                    <mat-label>Strecke (km)</mat-label>
                    <input type="number" step="1" min="0" [max]="vehicle?.liveData.rangeFull?.val || undefined"
                           matInput
                           [(ngModel)]="targetDistance">
                    <mat-hint>{{getUserSetDistanceHint()}}</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="fill" [color]="isValidUserSocDistance ? 'primary' : 'warn'">
                    <mat-label>Benötigter Ladestand (%)</mat-label>
                    <input type="number" step="1" max="100" min="0" matInput [(ngModel)]="targetSoc"
                           [disabled]="!allowSoc">
                    <mat-hint>Werte zwischen 1 und 100</mat-hint>
                </mat-form-field>
            </div>
        </div>

        <!-- Buchungszeitraum -->
        <div class="d-flex flex-column justify-content-between">
            <app-time-range-picker
                [(timeZone)]="data.timeZone"
                [asapAllowed]="true"
                [asapDisabled]="repeat"
                [(asap)]="data.asap"
                [(useDuration)]="data.useDuration"
                [(range)]="range"
                [min]="min"
                [startTooltip]="getBufferTimeTooltip()"
                [endTooltip]="getBufferTimeTooltip()"
            ></app-time-range-picker>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-stretch gap-1">
        <div class="d-flex flex-row justify-content-start">
            <mat-checkbox *ngIf="!repeat" [disabled]="asap" [(ngModel)]="repeat">Wiederholungen</mat-checkbox>
            <mat-form-field appearance="fill" *ngIf="repeat">
                <mat-label>Anzahl Wochen</mat-label>
                <mat-checkbox [disabled]="asap" [(ngModel)]="repeat" matSuffix matTooltip="Wiederholungen"></mat-checkbox>
                <input type="number" step="1" min="1"
                       matInput
                       [(ngModel)]="data.repeatCount">
            </mat-form-field>
            <div *ngIf="repeat" class="d-flex flex-wrap flex-row flex-grow-1 align-items-start mb-auto checkboxes">
                <mat-checkbox (change)="toggleDay('MONDAY')"     [checked]="isDayChecked('MONDAY')"      >Montag</mat-checkbox>
                <mat-checkbox (change)="toggleDay('TUESDAY')"    [checked]="isDayChecked('TUESDAY')"     >Dienstag</mat-checkbox>
                <mat-checkbox (change)="toggleDay('WEDNESDAY')"  [checked]="isDayChecked('WEDNESDAY')"   >Mittwoch</mat-checkbox>
                <mat-checkbox (change)="toggleDay('THURSDAY')"   [checked]="isDayChecked('THURSDAY')"    >Donnerstag</mat-checkbox>
                <mat-checkbox (change)="toggleDay('FRIDAY')"     [checked]="isDayChecked('FRIDAY')"      >Freitag</mat-checkbox>
                <mat-checkbox (change)="toggleDay('SATURDAY')"   [checked]="isDayChecked('SATURDAY')"    >Samstag</mat-checkbox>
                <mat-checkbox (change)="toggleDay('SUNDAY')"     [checked]="isDayChecked('SUNDAY')"      >Sonntag</mat-checkbox>
            </div>
        </div>
    </div>
</div>
<br><br>
<div>
    <app-vehicle-reservation-graph
        [startIn]="range.start"
        [endIn]="range.end"
        [selectedVehicle]="vehicle"
        [vehicles]="vehicles"
    ></app-vehicle-reservation-graph>
</div>
