import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {DefaultComponent} from 'src/app/layout/default/default.component';
import {SettingsViewComponent} from './modules/settings/views/settings-view.component';
import {EvseDetailsViewComponent} from './modules/evse/views/evse-detail-view/evse-details-view.component';
import {EvseListViewComponent} from './modules/evse/views/evse-list-view/evse-list-view.component';
import {SearchViewComponent} from './modules/search/views/search-view.component';
import {HelpViewComponent} from './modules/help/views/help-view/help-view.component';
import {UserDetailsViewComponent} from './modules/users/views/user-details-view/user-details-view.component';
import {LoginComponent} from 'src/app/layout/login/login.component';
import {PasswordResetComponent} from 'src/app/layout/password-reset/password-reset.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {UserListViewComponent} from './modules/users/views/user-list-view/user-list-view.component';
import {IconsComponent} from './modules/debug/icons/icons.component';
import {ReservationsListViewComponent} from './modules/reservations/views/reservation-list-view/reservations-list-view.component';
import {DongleListViewComponent} from './modules/dongle/views/dongle-list-view/dongle-list-view.component';
import {RfidListViewComponent} from './modules/rfid/views/rfid-list-view/rfid-list-view.component';
import {PermissionsGroupListViewComponent} from './modules/permissions/views/permissions-group-list-view/permissions-group-list-view.component';
import {PerformanceComponent} from './modules/debug/performance/performance.component';
import {AlgoConfigsTilesViewComponent} from './modules/algo-configs/views/algo-config-tiles-view/algo-configs-tiles-view.component';
import {QueueSizeComponent} from './modules/debug/queue-size/queue-size.component';
import {AlgoDebuggerViewComponent} from './modules/charge-plan/views/algo-debugger-view/algo-debugger-view.component';
import {MeterListViewComponent} from './modules/meter/views/meter-list-view/meter-list-view.component';
import {MeterDetailsViewComponent} from './modules/meter/views/meter-details-view/meter-details-view.component';
import {OcppDebuggerViewComponent} from './modules/ocpp-debugger/views/ocpp-debugger-view/ocpp-debugger-view.component';
import {OcppUplinksListViewComponent} from './modules/ocpp-uplinks/views/ocpp-uplinks-list-view/ocpp-uplinks-list-view.component';
import {LocalStorageComponent} from './modules/debug/local-storage/local-storage.component';
import {ServiceComponent} from './modules/debug/service/service.component';
import {LoadTilesViewComponent} from './modules/loads/views/load-tiles-view/load-tiles-view.component';
import {TeslaAccountsListViewComponent} from './modules/tesla/views/tesla-accounts-list-view/tesla-accounts-list-view.component';
import {
    EntityManagerDebuggerComponent
} from './modules/debug/entity-manager-debugger/entity-manager-debugger.component';
import {ApiDocComponent} from './modules/debug/api-doc/api-doc.component';
import {FreeToMoveListViewComponent} from './modules/free-to-move/views/free-to-move-list-view/free-to-move-list-view.component';
import {MeterConnectionDebugViewComponent} from "./modules/meter/views/meter-connection-debug-view/meter-connection-debug-view.component";
import {MeterLiveDataDebugViewComponent} from "./modules/meter/views/meter-live-data-debug-view/meter-live-data-debug-view.component";
import {LoadLiveDataViewComponent} from "./modules/loads/views/load-live-data/load-live-data-view.component";
import {OpenAdrVenListViewComponent} from "./modules/open-adr-ven-entity/views/open-adr-ven-list-view/open-adr-ven-list-view.component";
import {ChargingSessionSearchViewComponent} from './modules/charging-session/views/charging-session-search-view/charging-session-search-view.component';
import {LiveDataComponent} from "./modules/debug/live-data/live-data.component";
import {TronityLandingComponent} from "./layout/tronity-landing/tronity-landing.component";
import {ModbusConnectionsComponent} from "./modules/debug/modbus-connections/modbus-connections.component";
import {WhitelistViewComponent} from "./modules/whitelist/view/whitelist-view/whitelist-view.component";
import {ConfigurationListViewComponent} from './modules/configuration/views/configuration-list-view/configuration-list-view.component';
import {SolarTilesView} from "./modules/solar/views/solar-tiles-view/solar-tiles-view.component";
import {ChargePlanViewComponent} from './modules/charge-plan/views/charge-plan-view/charge-plan-view.component';
import {UnknownCpisListViewComponent} from "./modules/unknown-cpis/views/unknown-cpis-view/unknown-cpis-list-view.component";
import {DbPerformanceComponent} from "./modules/debug/db-performance/db-performance.component";
import {EvseBulkOperationsViewComponent} from "./modules/evse/views/evse-bulk-operations-view/evse-bulk-operations-view.component";
import {STecAccountsListViewComponent} from "./modules/s-tec-accounts/views/s-tec-accounts-list-view/s-tec-accounts-list-view.component";
import {EIconType} from './shared/components/icon/icon.component';
import {VehicleListViewComponent} from "./modules/vehicle/views/vehicle-list-view/vehicle-list-view.component";
import {VehicleDetailsViewComponent} from "./modules/vehicle/views/vehicle-details-view/vehicle-details-view.component";
import {
    VehicleKeyListViewComponent
} from "./modules/vehicle-key/views/vehicle-key-list-view/vehicle-key-list-view.component";
import {ExportComponent} from "./modules/debug/export/export.component";
import {FleetFaultStatisticsComponent} from './modules/evse-health/evse-fault-history-overview/fleet-fault-statistics/fleet-fault-statistics.component';
import {BasisTabGroupComponent} from './modules/basis/views/basis-tab-group/basis-tab-group.component';
import {TeslaLandingComponent} from './layout/tesla-landing/tesla-landing.component';
import {MigrationsComponent} from "./modules/debug/migrations/migrations.component";
import {
    MeterFormulaSimulationComponent
} from "./modules/meter/views/meter-formula-simulation/meter-formula-simulation.component";
import {RfidDetailsViewComponent} from './modules/rfid/views/rfid-details-view/rfid-details-view.component';
import {
    DongleDetailsViewComponent
} from './modules/dongle/views/dongle-details-view/dongle-details-view.component';
import {FlexViewComponent} from "./modules/flexibility-trading/views/flex-view/flex-view.component";
import {WebfleetAccountListComponent} from "./modules/webfleet/webfleet-account-list/webfleet-account-list.component";
import {
    VehicleTypesTableComponent
} from './modules/vehicle-types/components/vehicle-types-tree/vehicle-types-table.component';


const DEV_ONLY = ["DEVELOPER_PERMISSION"];

export interface ILookupHelper {
    lookupCurrentFleet(): Promise<string>
    lookupFleetByEvse(argName: string): Promise<string>
    lookupFleetByVehicle(argName: string): Promise<string>
    lookupFleetByMeter(argName: string): Promise<string>
    lookupEvse(argName: string): Promise<string>;
    lookupVehicle(argName: string): Promise<string>;
    lookupMeter(argName: string): Promise<string>;
    lookupRfid(argName: string): Promise<string>;
    lookupDongle(argName: string): Promise<string>;
}

export enum ELinkLocation {
    LEFT_MENU,
    TOP_RIGHT_MENU,
    TOP_RIGHT_MENU_DEV_PART
}

export interface ILinkDetails {
    text: string,
    icon: string,
    iconType: EIconType,
    location: ELinkLocation,
    sortIdx: number
}


export interface IRouteWithBreadcrumb extends Route {
    path: string,
    data: {
        breadcrumb: (string | ((lf: ILookupHelper) => Promise<string>))[];
        links: ILinkDetails[],
        globalPermissions: string[]
        fleetPermission?: string[]
    },
    children?: IRouteWithBreadcrumb[]
}

const devRoutes: IRouteWithBreadcrumb[] = [
    {
        path: 'liveData',
        component: LiveDataComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Live Daten'],
            links: [{
                text: "Live Daten",
                icon: "live_tv",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 100
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'migrations',
        component: MigrationsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Migrationen'],
            links: [{
                text: "Migrationen",
                icon: "healing",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 100
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'modbusConnections',
        component: ModbusConnectionsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Modbus Verbindungen'],
            links: [{
                text: "Modbus Verbindungen",
                icon: "fa-solid fa-network-wired",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 200
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'openAdrVenEntity',
        component: OpenAdrVenListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'OpenAdr Ven'],
            links: [{
                text: "OpenAdr Ven",
                icon: "fa-solid fa-industry",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 300
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'meterConnections',
        component: MeterConnectionDebugViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Zähler Verbindungsdetails'],
            links: [{
                text: "Zähler Verbindungsdetails",
                icon: "surround_sound",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 400
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'meterLiveData',
        component: MeterLiveDataDebugViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Zähler Live Daten'],
            links: [{
                text: "Zähler Live Daten",
                icon: "filter_center_focus",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 500
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'loadLiveData',
        component: LoadLiveDataViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Lasten Live Daten'],
            links: [{
                text: "Lasten Live Daten",
                icon: "fa-solid fa-house-signal",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 600
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'icons',
        component: IconsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Icons'],
            links: [{
                text: "Icons",
                icon: "terrain",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 700
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'performance',
        component: PerformanceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Performance'],
            links: [{
                text: "Performance",
                icon: "timer",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 800
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'performanceDb',
        component: DbPerformanceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Datenbank Performance'],
            links: [{
                text: "Datenbank Performance",
                icon: "grid_on",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 900
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'queueSize',
        component: QueueSizeComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Queue Sizes'],
            links: [{
                text: "Queue Sizes",
                icon: "playlist_add_check",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1000
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'algoDebugger',
        component: AlgoDebuggerViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Algo Debugger'],
            links: [{
                text: "Algo Debugger",
                icon: "av_timer",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1100
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'entityManager',
        component: EntityManagerDebuggerComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Entity Manager Debugger'],
            links: [{
                text: "Entity Manager Debugger",
                icon: "storage",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1200
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'services',
        component: ServiceComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Services'],
            links: [{
                text: "Services",
                icon: "all_inclusive",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1300
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'localStorage',
        component: LocalStorageComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Local Storage'],
            links: [{
                text: "Local Storage",
                icon: "settings_applications",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1500
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'ocppDebugger',
        component: OcppDebuggerViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'OCPP Debugger'],
            links: [{
                text: "OCPP Debugger",
                icon: "question_answer",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1600
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'configurations',
        component: ConfigurationListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Konfigurationen'],
            links: [{
                text: "Konfigurationseinträge",
                icon: "build",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1800
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'export',
        component: ExportComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Export'],
            links: [{
                text: "Export",
                icon: "fa-solid fa-file-export",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1800
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'load-formular-simulator/:id',
        component: MeterFormulaSimulationComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Formel Last Simulator'],
            links: [{
                text: "Formel Last Simulator",
                icon: "fa-solid fa-microscope",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1900
            }],
            globalPermissions: DEV_ONLY
        }
    },
    {
        path: 'vehicleModels',
        component: VehicleTypesTableComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ["Debugging", 'Fahrzeug-Typen'],
            links: [{
                text: "Fahrzeug-Typen",
                icon: "fa-solid fa-car",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU_DEV_PART,
                sortIdx: 1900
            }],
            globalPermissions: DEV_ONLY
        }
    }
]

const topRightRoutes: IRouteWithBreadcrumb[] = [
    {
        path: 'settings',
        component: SettingsViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Einstellungen'],
            links: [{
                text: "Einstellungen",
                icon: "settings",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 100
            }],
            globalPermissions: []
        }
    },
    {
        path: 'user',
        component: UserListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Benutzer'],
            links: [{
                text: "Benutzerverwaltung",
                icon: "supervised_user_circle",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 200
            }],
            globalPermissions: ["VIEW_USERS"]
        }
    },
    {
        path: 'userStatistics',
        component: FleetFaultStatisticsComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Meine Statistiken'],
            links: [{
                text: 'Meine Statistiken',
                icon: 'query_stats',
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 300
            }],
            globalPermissions: ['VIEW_EVSE_STATISTICS']
        }
    },
    {
        path: 'permissions',
        component: PermissionsGroupListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Berechtigungen'],
            links: [{
                text: "Berechtigungen Verwalten",
                icon: "playlist_add_check",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 300
            }],
            globalPermissions: ['VIEW_PERMISSIONS']
        }
    },
    {
        path: 'datasources',
        data: {
            breadcrumb: ['Datenquellen'],
            links: [],
            globalPermissions: ['VIEW_TESLA_APIS', 'VIEW_DONGLES']
        },
        children: [{
            path: 'datasource/:id',
            component: DongleDetailsViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [lf => lf.lookupDongle("id")],
                links: [],
                globalPermissions: []
            }
        }, {
            path: '',
            component: DongleListViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [],
                links: [{
                    text: "Dongles & Datenquellen",
                    icon: "router",
                    iconType: EIconType.MATERIAL,
                    location: ELinkLocation.TOP_RIGHT_MENU,
                    sortIdx: 400
                }],
                globalPermissions: ['VIEW_TESLA_APIS', 'VIEW_DONGLES']
            }
        }]
    },
    {
        path: 'rfids',
        data: {
            breadcrumb: ['Rfid'],
            links: [],
            globalPermissions: ["VIEW_RFIDS"]
        },
        children: [{
            path: 'rfid/:id',
            component: RfidDetailsViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [lf => lf.lookupRfid("id")],
                links: [],
                globalPermissions: []
            }
        }, {
            path: '',
            component: RfidListViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [],
                links: [{
                    text: "RFID Karten",
                    icon: "sim_card",
                    iconType: EIconType.MATERIAL,
                    location: ELinkLocation.TOP_RIGHT_MENU,
                    sortIdx: 500
                }],
                globalPermissions: ["VIEW_RFIDS"]
            }
        }]
    },
    {
        path: 'flex',
        component: FlexViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Flexibilität Handel'],
            links: [{
                text: "Flexibilität Handel",
                icon: "fas fa-money-bill-trend-up",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 550
            }],
            globalPermissions: ["VIEW_FLEETS"]
        }
    },
    {
        path: 'ocppUplinks',
        component: OcppUplinksListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['OCPP Abrechnungsbackends'],
            links: [{
                text: "OCPP Abrechnungsbackends",
                icon: "euro_symbol",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 600
            }],
            globalPermissions: ["VIEW_OCPP_UPLINKS"]
        }
    },
    {
        path: 'bulkOperation',
        component: EvseBulkOperationsViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Bulk Operationen'],
            links: [{
                text: "Bulk Operationen",
                icon: "bulk_operations",
                iconType: EIconType.CUSTOM,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 700
            }],
            globalPermissions: ["EDIT_EVSES"],
            fleetPermission: ["EDIT_EVSES_OF_BASIS"]
        }
    },
    {
        path: 'loads',
        component: LoadTilesViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Lasten'],
            links: [{
                text: "Dynamisches Lastmanagement",
                icon: "call_split",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 800
            }],
            globalPermissions: ["VIEW_DYNAMIC_LOAD"],
            fleetPermission: ["VIEW_ENERGY_SYSTEMS_OF_BASIS"]
        }
    },
    {
        path: 'meters',
        data: {
            breadcrumb: [lf => lf.lookupFleetByMeter("id"), 'Zähler'],
            links: [],
            globalPermissions: ["VIEW_METERS"],
            fleetPermission: ["VIEW_ENERGY_SYSTEMS_OF_BASIS"]
        },
        children: [{
            path: 'meter/:id',
            component: MeterDetailsViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [lf => lf.lookupMeter("id")],
                links: [],
                globalPermissions: []
            }
        }, {
            path: '',
            component: MeterListViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [],
                links: [{
                    text: "Zähler",
                    icon: "monitor_heart",
                    iconType: EIconType.MATERIAL,
                    location: ELinkLocation.TOP_RIGHT_MENU,
                    sortIdx: 900
                }],
                globalPermissions: ["VIEW_METERS"],
                fleetPermission: ["VIEW_ENERGY_SYSTEMS_OF_BASIS"]
            }
        }]
    },
    {
        path: 'whitelist',
        component: WhitelistViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Validierung Ausnahmen'],
            links: [{
                text: "Validierung Ausnahmen",
                icon: "fas fa-check-double",
                iconType: EIconType.FONT_AWESOME,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1000
            }],
            globalPermissions: ["VIEW_EVSE_VALIDATION_WHITELIST"]
        }
    },
    {
        path: 'teslaAccounts',
        component: TeslaAccountsListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Tesla Accounts'],
            links: [{
                text: "Tesla Accounts",
                icon: "tesla",
                iconType: EIconType.CUSTOM,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1100
            }],
            globalPermissions: ["VIEW_TESLA_ACCOUNTS"]
        }
    },
    {
        path: 'sTecAccounts',
        component: STecAccountsListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['S-Tec Accounts'],
            links: [{
                text: "S-Tec Accounts",
                icon: "car_control",
                iconType: EIconType.CUSTOM,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1200
            }],
            globalPermissions: ["VIEW_S_TEC_ACCOUNTS"]
        }
    },
    {
        path: 'freeToMove',
        component: FreeToMoveListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Free2Move Verknüpfungen'],
            links: [{
                text: "Free2Move (Connected Fleet) Verknüpfungen",
                icon: "free2move",
                iconType: EIconType.CUSTOM,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1300
            }],
            globalPermissions: ["VIEW_FREE_TO_MOVE_DATASOURCES"]
        }
    },
    {
        path: 'webfleet',
        component: WebfleetAccountListComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Webfleet Accounts'],
            links: [{
                text: "Webfleet Accounts",
                icon: "webfleet",
                iconType: EIconType.CUSTOM,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1300
            }],
            globalPermissions: ["VIEW_WEBFLEET_ACCOUNTS"]
        }
    },
    {
        path: 'swaggerDoc',
        component: ApiDocComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['API-Dokumentation'],
            links: [{
                text: "API-Dokumentation",
                icon: "developer_mode",
                iconType: EIconType.MATERIAL,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1400
            }],
            globalPermissions: ["VIEW_API"]
        }
    },
    {
        path: 'algoConfigs',
        component: AlgoConfigsTilesViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Ladeplan Parameter'],
            links: [{
                text: "Ladeplan-Parameter",
                icon: "av_timer",
                iconType: EIconType.MATERIAL_OUTLINE,
                location: ELinkLocation.TOP_RIGHT_MENU,
                sortIdx: 1500
            }],
            globalPermissions: ["VIEW_ALGO_V2_CONFIG"]
        }
    }
]

const leftRoutes: IRouteWithBreadcrumb[] = [
    {
        path: 'fleet',
        data: {
            breadcrumb: [lf => lf.lookupFleetByVehicle("id"), "Fahrzeuge"],
            links: [],
            globalPermissions: []
        },
        children: [{
            path: '',
            component: VehicleListViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [],
                links: [{
                    text: "Flotte",
                    icon: "directions_car",
                    iconType: EIconType.MATERIAL,
                    location: ELinkLocation.LEFT_MENU,
                    sortIdx: 100
                }],
                globalPermissions: []
            }
        }, {
            path: 'vehicle/:id',
            component: VehicleDetailsViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [lf => lf.lookupVehicle("id")],
                links: [],
                globalPermissions: []
            }
        }]
    },
    {
        path: 'evses',
        data: {
            breadcrumb: [lf => lf.lookupFleetByEvse("id"), "Ladepunkte"],
            links: [],
            globalPermissions: []
        },
        children: [{
            path: 'evse/:id',
            component: EvseDetailsViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [lf => lf.lookupEvse("id")],
                links: [],
                globalPermissions: []
            }
        },{
            path: '',
            component: EvseListViewComponent,
            canActivate: [AuthGuard],
            data: {
                breadcrumb: [],
                links: [{
                    text: "Ladepunkte",
                    icon: "ladestation",
                    iconType: EIconType.CUSTOM,
                    location: ELinkLocation.LEFT_MENU,
                    sortIdx: 200
                }],
                globalPermissions: []
            },
        }]
    },
    {
        path: 'chargePlan',
        component: ChargePlanViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [lf => lf.lookupCurrentFleet() ,"Ladeplan"],
            links: [{
                text: "Ladeplan",
                icon: "line_style",
                iconType: EIconType.MATERIAL_OUTLINE,
                location: ELinkLocation.LEFT_MENU,
                sortIdx: 300
            }],
            globalPermissions: []
        }
    },
    {
        path: 'reservations',
        component: ReservationsListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [lf => lf.lookupCurrentFleet() ,'Buchungen'],
            links: [{
                text: "Buchungen",
                icon: "event",
                iconType: EIconType.MATERIAL_OUTLINE,
                location: ELinkLocation.LEFT_MENU,
                sortIdx: 400
            }],
            globalPermissions: []
        }
    },
    {
        path: 'vehicleKey',
        component: VehicleKeyListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [lf => lf.lookupCurrentFleet(), 'Schlüsselverwaltung'],
            links: [{
                text: "Schlüssel-\nverwaltung",
                icon: "vpn_key",
                iconType: EIconType.MATERIAL_OUTLINE,
                location: ELinkLocation.LEFT_MENU,
                sortIdx: 500
            }],
            globalPermissions: ["VIEW_VEHICLE_KEYS"]
        }
    },
    {
        path: 'basis',
        component: BasisTabGroupComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [lf => lf.lookupCurrentFleet(), 'Standort'],
            links: [{
                text: "Standort",
                icon: "location_city",
                iconType: EIconType.MATERIAL_OUTLINE,
                location: ELinkLocation.LEFT_MENU,
                sortIdx: 600
            }],
            globalPermissions: []
        }
    },
    {
        path: 'solar',
        component: SolarTilesView,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [lf => lf.lookupCurrentFleet(), 'Solar'],
            links: [{
                text: "Solar",
                icon: "wb_sunny",
                iconType: EIconType.MATERIAL_OUTLINE,
                location: ELinkLocation.LEFT_MENU,
                sortIdx: 700
            }],
            globalPermissions: []
        }
    },
    {
        path: 'chargingSession',
        component: ChargingSessionSearchViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Ladevorgänge'],
            links: [{
                text: "Ladevorgänge",
                icon: "graphic",
                iconType: EIconType.CUSTOM_WHITE,
                location: ELinkLocation.LEFT_MENU,
                sortIdx: 800
            }],
            globalPermissions: ["VIEW_EVSES"],
            fleetPermission: ["VIEW_EVSES_OF_BASIS"]
        }
    }
]

const specialRoutes: IRouteWithBreadcrumb[] = [
    {
        path: 'search',
        component: SearchViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: [lf => lf.lookupCurrentFleet(), 'Suchen'],
            links: [],
            globalPermissions: []
        }
    },
    {
        path: 'unknownCpis',
        component: UnknownCpisListViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Unbekannte CPIs'],
            links: [],
            globalPermissions: []
        }
    },
    {
        path: 'help',
        component: HelpViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Hilfe'],
            links: [],
            globalPermissions: []
        }
    },
    {
        path: 'user',
        component: UserDetailsViewComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: ['Benutzer'],
            links: [],
            globalPermissions: []
        }
    },
    {
        path: '**',
        redirectTo: "/login",
        data: {
            breadcrumb: [],
            links: [],
            globalPermissions: []
        }
    },
    {
        path: '',
        pathMatch: "full",
        redirectTo: "/login",
        data: {
            breadcrumb: [],
            links: [],
            globalPermissions: []
        }
    }
]

export const FRAMED_ROUTES: IRouteWithBreadcrumb[] = [
    ...topRightRoutes,
    ...devRoutes,
    ...leftRoutes,
    ...specialRoutes
]

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    }, {
        path: 'reset',
        component: PasswordResetComponent
    }, {
        path: 'tronityOk',
        component: TronityLandingComponent
    }, {
        path: 'tronityError',
        component: TronityLandingComponent
    }, {
        path: 'teslaOk',
        component: TeslaLandingComponent
    }, {
        path: 'teslaError',
        component: TeslaLandingComponent
    }, {
        path: '',
        component: DefaultComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: null},
        children: FRAMED_ROUTES
    }
];

export interface IMenuEntry extends ILinkDetails {
    link: string,
    globalPermissions: string[]
    fleetPermission: string[]
}

export function getMenu(type: ELinkLocation): IMenuEntry[] {
    const menu: IMenuEntry[] = [];

    for(const r of FRAMED_ROUTES) {
        menu.push(...getMenus(r))
    }

    return menu.filter(m => m.location === type).sort((a,b) => a.sortIdx - b.sortIdx);
}

function getMenus(route: IRouteWithBreadcrumb, parent = ""): IMenuEntry[] {
    const menu: IMenuEntry[] = [];

    for(const l of route.data.links) {
        menu.push({
            ...l,
            link: parent + "/" + route.path,
            globalPermissions: route.data.globalPermissions,
            fleetPermission: route.data.fleetPermission ?? []
        });
    }

    if(route.children) {
        for(const c of route.children) {
            menu.push(...getMenus(c, route.path))
        }
    }

    return menu;
}




@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
