import {Injectable} from '@angular/core';
import {
    ChargePlan,
    ChargingSessionList,
    ExecuteFleetUpdate,
    Fleet, FleetFlexCreateDerSystemArgs, FleetFlexInfo,
    FleetOverview,
    Reservation,
    ReservationInstance
} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from '../../../services/api-handlers/api.service';
import {CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';
import {IEditForm, TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {EditFleetDialogComponent} from '../../basis/dialogs/edit-fleet-dialog/edit-fleet-dialog.component';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = 7 * DAY;
const UPCOMING_TIME = WEEK; // Reservierungen vorschau

interface ChargePlanCacheIdentifier {
    fleetId: number,
    startTst: number | undefined
}
@Injectable({
    providedIn: 'root'
})
export class FleetService extends DialogHandler<Fleet, ExecuteFleetUpdate, never, {}, void, void> {
    private _selectedFleet!: BehaviorSubject<number | undefined>
    private overviewCache!: CacheUpdater<FleetOverview, number>;
    private chargePlanCache!: CacheUpdater<ChargePlan, ChargePlanCacheIdentifier>;
    private activeChargingSessionsCache!: CacheUpdater<ChargingSessionList, number>;
    private reservationsCache!: CacheUpdater<Reservation[], number>;
    private reservationsUpcomingCache!: CacheUpdater<ReservationInstance[], number>;
    private flexInfoCache!: CacheUpdater<FleetFlexInfo, number>

    constructor(apiService: ApiService, toastr: ToastrService, dialog: MatDialog, dialogService: DialogService) {
        super(apiService, "Fleet", toastr, dialog, dialogService, POLL_INTERVALS.fleet);
        this.resetSelectedFleet();
    }

    get selectedFleet(): BehaviorSubject<number | undefined> {
        return this._selectedFleet;
    }

    public setSelectedFleet(fleetId: number) {
        this.selectedFleet.next(fleetId);
    }

    public resetSelectedFleet(){
        this._selectedFleet = new BehaviorSubject<number | undefined>(undefined);
        this.overviewCache = this.createManagedCache((obj, id) => obj.id === id);
        this.chargePlanCache = this.createManagedCache((obj, id) => obj.id.fleetId === id.fleetId && obj.id.startTst === id.startTst, POLL_INTERVALS.chargePlan);
        this.reservationsCache = this.createManagedCache((obj, id) => obj.id === id);
        this.reservationsUpcomingCache = this.createManagedCache((obj, id) => obj.id === id);
        this.activeChargingSessionsCache = this.createManagedCache((obj, id) => obj.id === id);
        this.flexInfoCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    public getOverview(id: number, showAlerts = true): BehaviorSubject<FleetOverview | undefined> {
        return this.overviewCache.getOrCreateGet(id, () => this.apiService.getFleetOverview(showAlerts, id, undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    public getChargePlan(id: number, startTst: number | undefined, showAlerts = true): BehaviorSubject<ChargePlan | undefined> {
        return this.chargePlanCache.getOrCreateGet({fleetId: id, startTst: startTst || Date.now()}, () => this.apiService.getFleetChargePlan(showAlerts, id, startTst || Date.now(), undefined, undefined, ApiHandler.customerId).toPromise()).data;
    }

    public getChargePlanPromise(id: number, showAlerts = true): Promise<ChargePlan> {
        return this.wrapInPromise(() => this.getChargePlan(id, undefined, showAlerts));
    }

    public getActivChargingSessions(fleetId: number, showAlerts = true): BehaviorSubject<ChargingSessionList | undefined> {
        return this.activeChargingSessionsCache.getOrCreateGet(
            fleetId,
            () => this.apiService.fleetGetActiveChargings(showAlerts, fleetId, undefined, undefined, ApiHandler.customerId).toPromise()
        ).data;
    }

    public getReservations(fleetId: number, showAlerts = true): BehaviorSubject<Reservation[] | undefined> {
        return this.reservationsCache.getOrCreateGet(
            fleetId,
            () => this.apiService.getFleetReservations(showAlerts, fleetId, undefined, undefined, ApiHandler.customerId).toPromise().then(list => list.list)
        ).data;
    }

    public getReservationsUpcoming(fleetId: number, showAlerts = true): BehaviorSubject<ReservationInstance[] | undefined> {
        return this.reservationsUpcomingCache.getOrCreateGet(
            fleetId,
            () => this.getReservationsByTime(fleetId, Date.now(), Date.now() + UPCOMING_TIME, showAlerts)
        ).data;
    }

    public getReservationsByTime(fleetId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<ReservationInstance[]> {
        if (!(typeof start === 'number')) {
            start = start.getTime();
        }
        if (!(typeof end === 'number')) {
            end = end.getTime();
        }
        return this.apiService.getFleetReservationInstancesInTimeRange(showAlerts, fleetId, start, end, undefined, undefined, ApiHandler.customerId).toPromise().then(l => l.list);
    }

    protected getEditConfig(fleet: Fleet): TDialogOptions<ExecuteFleetUpdate, IEditForm<ExecuteFleetUpdate>> {
        return {
            component: EditFleetDialogComponent,
            headline: "Flotte Bearbeiten",
            executeCallback: editResult => this.update(fleet.id, {
                name: editResult.name,
                algoV2ConfigId: editResult.algoV2ConfigId === -1 ? undefined : editResult.algoV2ConfigId,
                rfidTrainingMode: editResult.rfidTrainingMode,
                rangeCorrectionFactor: editResult.rangeCorrectionFactor,
                defaultSoh: editResult.defaultSoh,
                reservationTimeBuffer: editResult.reservationTimeBuffer,
                reservationOvercharge: editResult.reservationOvercharge
            }),
            editElement: {
                name: fleet.name,
                algoV2ConfigId: fleet.algoV2ConfigId,
                rfidTrainingMode: fleet.rfidTrainingMode,
                rangeCorrectionFactor:fleet.rangeCorrectionFactor,
                defaultSoh: fleet.defaultSoh,
                reservationTimeBuffer: fleet.reservationTimeBuffer,
                reservationOvercharge: fleet.reservationOvercharge
            }
        };
    }

    protected getNewConfig(): never {
        throw new Error("No new Fleet Dialog");
    }

    public refreshPlan(fleetId: number, showAlerts = true): Promise<void> {
        return this.wrapInPromise(() => this.apiService.fleetInvalidatePlan(showAlerts, fleetId, undefined, undefined, ApiHandler.customerId) as unknown as BehaviorSubject<void | undefined>);
    }

    public getFlexInfo(fleetId: number, showAlerts = true): BehaviorSubject<FleetFlexInfo | undefined> {
        return this.flexInfoCache.getOrCreateGet(fleetId, () => this.apiService.getFlexInfo(showAlerts, fleetId, undefined, undefined, ApiHandler.customerId).toPromise()).data
    }

    createDerSystem(fleetId: number, createDerSystemArgs: FleetFlexCreateDerSystemArgs, showWarning = true): Promise<unknown> {
        return this.apiService.createFlexDerSystem(showWarning, fleetId, undefined, undefined, ApiHandler.customerId, createDerSystemArgs).toPromise();
    }
}
