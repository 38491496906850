import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {
    ExecuteReservationAddOrUpdate,
    ExecuteReservationDelete,
    ReservationInstance,
    Vehicle,
    VehicleTeaser
} from '@io-elon-common/frontend-api';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ZoomEvent} from '../../../../shared/helper/zoom-helper.directive';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ReservationService} from '../../service/reservation.service';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {DelayedExecutor} from '../../../../shared/helper/DelayedExecutor';

const DAY = 24 * 3600 * 1000;

type HeaderLabel = {
    style: string,
    line1: string,
    line2: string
};

@Component({
    selector: 'app-vehicle-reservation-graph',
    templateUrl: './vehicle-reservation-graph.component.html',
    styleUrls: ['./vehicle-reservation-graph.component.scss']
})
export class VehicleReservationGraphComponent<T extends (VehicleTeaser | Vehicle)> implements OnInit, OnChanges, OnDestroy {
    @Input() startIn!: Moment;
    @Input() endIn!: Moment;
    @Input() vehicles!: T[];
    @Input() selectedVehicle!: T;
    @Input() editEnabled = false;

    @Output() handleEdit = new EventEmitter<{res: ReservationInstance, mode: ExecuteReservationAddOrUpdate.EditModeEnum}>();
    @Output() handleDelete = new EventEmitter<{ res: ReservationInstance, mode: ExecuteReservationDelete.DeleteModeEnum }>();

    public reloadExecutor = new DelayedExecutor(300);

    private start = moment();
    private end = moment();


    @ViewChild(MatPaginator, {static: true}) public paginator!: MatPaginator;
    public dataSource = new MatTableDataSource<T>([]);
    public displayedColumns: string[] = [
        'name',
        'graph'];

    public reservations!: Array<BehaviorSubject<ReservationInstance[] | undefined>>; // Sparse Array, index is vehicleId
    private reservationsSubscriptions!: Array<Subscription>;


    constructor(
        private readonly reservationService: ReservationService,
        private readonly vehicleService: VehicleService
    ) {
    }

    public get refreshActive(): boolean {
        return this.reservations.some(bs => bs.getValue() === undefined);
    }

    async ngOnInit(): Promise<void> {
        this.start = this.startIn.clone().subtract(2, 'hours').startOf('hour');
        this.end = this.endIn.clone().add(2, 'hours').startOf('hour');

        this.dataSource = new MatTableDataSource<T>(this.vehicles);
        this.dataSource.paginator = this.paginator;

        this.reservations = [];
        this.reservationsSubscriptions = [];
        this.updateReservations();
    }

    public ngOnChanges(changes?: SimpleChanges) {
        this.start = this.startIn.clone().subtract(2, 'hours').startOf('hour');
        this.end = this.endIn.clone().add(2, 'hours').startOf('hour');

        this.updateReservations();

        this.dataSource.data = this.vehicles;
    }

    public ngOnDestroy(): void {
        this.reservationsSubscriptions.forEach(s => s.unsubscribe());
    }

    private updateReservations() {
        this.reloadExecutor.execute(() => {
            this.reservationsSubscriptions.forEach(s => s.unsubscribe());
            this.vehicles.forEach(v => {
                const newSubject = this.vehicleService.getReservationsByTime(v.id, this.start.valueOf(), this.end.valueOf());
                const subscription = newSubject.subscribe(val => {
                    if(!val) {
                        return;
                    }
                    this.reservations[v.id] = newSubject;
                });
                this.reservationsSubscriptions.push(subscription);
            });
        });
    }

    public getBattery(vehicle: VehicleTeaser): string {
        if (vehicle?.liveData?.estimatedSocU !== undefined) {
            return vehicle.liveData.estimatedSocU?.val.toFixed(1);
        }
        return '--';
    }

    public getReservations(vehicle: T): ReservationInstance[] {
        return this.reservations[vehicle.id]?.getValue()?.filter(r => {
            const start = this.start.valueOf();
            const end = this.end.valueOf();
            const endRes = r.end;
            const startRes = r.start;

            return endRes > start && startRes < end;
        }).sort((r1, r2) => r2.start - r1.start) || []; // Leeres Array wenn noch keine Reservations geladen wurden
    }

    public getDayLinePositions(): Array<string> {
        return this.getGridPositions().map(t => `left: ${this.getX(t)}%;`);
    }

    public getWeekends(): Array<string> {
        const result: string[] = [];
        const day = this.start.clone().startOf('day');

        while (day.isBefore(this.end)) {
            if (day.isoWeekday() > 5) {
                const t = day.valueOf();
                result.push(`left: ${this.getX(t)}%; right: ${100 - this.getX(t + DAY)}%;`);
            }
            day.add(1, 'days');
        }

        return result;
    }

    public getNow(): string {
        let t = Date.now();
        t -= t % 10000;
        const x = this.getX(t);
        if (x <= 0 || x >= 100) {
            return 'visibility: hidden;';
        }
        return `left: ${x}%; right: ${100 - x - 1}%;`;
    }

    public getHighlight(veh: T): { visible: boolean, style: string } {
        if (veh !== this.selectedVehicle) {
            return {
                visible: false,
                style: ''
            };
        }
        return {
            visible: true,
            style: `left: ${this.getX(this.startIn)}%; right: ${100 - this.getX(this.endIn)}%;`
        };
    }

    public getX(time: number | Moment): number {
        const start = this.start.valueOf();

        if (typeof time !== 'number') {
            time = time.valueOf();
        }
        return this.getXRelative(time - start);
    }

    public getXRelative(time: number) {
        const start = this.start.valueOf();
        const end = this.end.valueOf();
        const fullDuration = end - start;

        return Math.min(100, Math.max(0, time / fullDuration * 100));
    }

    public getHeaderTimes(): Array<HeaderLabel> {
        const offset = (DAY + new Date().getTimezoneOffset() * 60 * 1000) % DAY;
        let firstX = 100;
        const result: HeaderLabel[] = this.getGridPositions().map(t => {
            const x = this.getX(t);
            const showDate = x < 93 && t % (1000*60*60*24) === offset;
            const showTime = x < 95;
            if(showDate) {
                firstX = Math.min(x, firstX);
            }
            return {
                style: `left: ${x}%;`,
                line1: showDate ? moment(t).locale('DE-de').format('dd DD.MM.') : "",
                line2: showTime ? moment(t).format('HH:mm') : ""
            };
        });

        if(firstX > 10){
            // Initial Date
            result.push({
                style: `left: ${this.getX(this.start.valueOf())}%;`,
                line1: moment(this.start).locale('DE-de').format('dd DD.MM.'),
                line2: ""
            })
        }

        return result;
    }

    private getGridPositions(): number[] {
        const gridPositions: number[] = [];
        const start = this.start.valueOf();
        const startMinutes = start / 1000 / 60;
        const deltaMinutes = this.end.diff(this.start, 'minutes');
        const gridDistance = this.getGridDistance();
        const gridOffset = startMinutes % gridDistance
        let startGrid = gridDistance - gridOffset;
        const inDayOffset = startGrid % (60*24);
        const distBack = startGrid - inDayOffset;
        startGrid = startGrid - (distBack % gridDistance) + new Date().getTimezoneOffset()

        for (let x = startGrid; x < deltaMinutes; x += gridDistance) {
            // Ohne runden kommen hier manchmal seltsame Double-Genauigkeitsfehler rein
            gridPositions.push(Math.round(x * 1000 * 60 + start));
        }

        return gridPositions;
    }

    private getGridDistance(): number {
        const deltaMinutes = this.end.diff(this.start, 'minutes');

        // Werte mit Geogebra einfach aus einer Linearen regression für ein paar Punkte ermittelt, 0.079+10 ist also irgendwie Zufall ^^
        let regression = deltaMinutes * 0.079 + 10;
        regression = regression - regression % 60;

        // Raster ist kleiner als 1 Tag, dann auf 0,5, 1, 2, 3, 4, 6, 12 oder 24 Stunden Runden, damit jeder Tag bei 0 anfangen kann
        if(regression < 60*24) {
            if(regression < 30) { // 0,5 Stunden
                regression = 30;
            } else if(regression < 60){ // 1 Stunde
                regression = 60;
            } else if(regression < 120) { // 2 Stunden
                regression = 120
            } else if(regression < 3 * 60) {
                regression = 3 * 60
            } else if(regression < 4 * 60) {
                regression = 4 * 60
            } else if(regression < 6 * 60) {
                regression = 6 * 60
            } else if(regression < 12 * 60) {
                regression = 12 * 60
            } else {
                regression = 24 * 60
            }
        } else {
            regression = regression - regression % 24 * 60; // auf ganze Tage runden
        }
        return regression;
    }

    public getTooltip(res: ReservationInstance): string {
        return moment(res.start+ res.timeBuffer).format('HH:mm') + ' - '
            + moment(res.end).format('HH:mm') + ', '
            + res.reservation.distance + ' km, '
            + (res.reservation.driver?.name || " kein Fahrer");
    }

    public getBufferTooltip(res: ReservationInstance): string {
        return `Puffer ${moment(res.start).format("HH:mm")} - `
            +`${moment(res.start + res.timeBuffer).format("HH:mm")} `
            + `für Reservierung ${moment(res.start + res.timeBuffer).format("HH:mm")} - `
            +`${moment(res.end).format("HH:mm")}, `
            +`${res.reservation.distance} km, `
            +`${res.reservation.driver?.name || " kein Fahrer"}`;
    }

    public calcStyle(res: ReservationInstance): string {
        let leftPerc = this.getX(res.start+ res.timeBuffer);
        let rightPerc = this.getX(res.end);

        let border = '';
        if (leftPerc < 0) {
            leftPerc = 0;
            border += 'border-left: dashed black 3px;';
        }

        if (rightPerc - leftPerc < 3) {
            rightPerc = leftPerc + 3;
        }

        if (rightPerc > 100) {
            rightPerc = 100;
            border += 'border-right: dashed black 3px;';
        }

        const hidden = rightPerc - leftPerc < 10 ? "font-size:0;" : "";
        rightPerc = 100 - rightPerc;
        return `left: ${leftPerc}%; right: ${rightPerc}%; ${border} ${hidden}`;
    }

    public calcStyleBuffer(res: ReservationInstance): string {
        let leftPerc = this.getX(res.start);
        let rightPerc = this.getX(res.start+ res.timeBuffer);

        let border = '';
        if (leftPerc < 0) {
            leftPerc = 0;
            border += 'border-left: dashed black 3px;';
        }

        if (rightPerc - leftPerc < 3) {
            rightPerc = leftPerc + 3;
        }

        if (rightPerc > 100) {
            rightPerc = 100;
            border += 'border-right: dashed black 3px;';
        }

        const hidden = rightPerc - leftPerc < 10 ? "font-size:0;" : "";
        rightPerc = 100 - rightPerc;
        return `left: ${leftPerc}%; right: ${rightPerc}%; ${border} ${hidden}`;
    }

    public formatReservationLine1(res: ReservationInstance): string {
        const start = moment(res.start + res.timeBuffer);
        const end = moment(res.end);
        const startTime = start.format("HH:mm");
        const endTime = end.format("HH:mm");
        const startDate = start.locale('DE-de').format('DD.MM.');
        const endDate = end.locale('DE-de').format('DD.MM.');

        if(startDate !== endDate) {
            return `${startDate}  ${startTime} bis ${endDate} ${endTime}`
        } else {
            return `${startDate}  ${startTime} bis ${endTime}`
        }
    }

    public onDragX(dx: number) {
        const time = this.start.valueOf() - this.end.valueOf();
        const size = document.getElementById('graphHeader')?.clientWidth;

        if (!size) {
            return;
        }

        const dt = dx / size * time;

        this.start.add(dt, 'milliseconds');
        this.end.add(dt, 'milliseconds');
        this.updateReservations();
    }

    @HostListener('dblclick')
    public dblClick(): void {
        this.start = this.startIn.clone().subtract(2, 'hours').startOf('hour');
        this.end = this.endIn.clone().add(2, 'hours').startOf('hour');
    }

    public onZoom(event: ZoomEvent): void {
        const header = document.getElementById('graphHeader');
        const left = header?.getBoundingClientRect().left as number;
        const right = left + (header?.clientWidth as number);

        const percentageMouse = (event.clientX - left) / (right - left);
        const s = this.start.valueOf();
        const e = this.end.valueOf();
        const w = e - s;
        const newE = e + w * 0.1 * (1 - percentageMouse) * event.direction;
        const newS = s - w * 0.1 * percentageMouse * event.direction;
        this.start = moment(newS);
        this.end = moment(newE);
        this.updateReservations();
    }

    public async edit(res: ReservationInstance, mode: ExecuteReservationAddOrUpdate.EditModeEnum): Promise<void> {
        this.handleEdit.emit({res, mode});
    }

    public async delete(res: ReservationInstance, mode: ExecuteReservationDelete.DeleteModeEnum): Promise<void> {
        this.handleDelete.emit({res, mode});
    }
}
