import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function required(err: string): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
        if ((ctrl.value ?? false) !== false) {
            return null;
        } else {
            return {required: err};
        }
    };
}

type TypeOfOptions = "undefined" | "object" | "boolean" | "number" | "bigint" | "string" | "symbol" | "function";
export function isTypeOf(expectedType: TypeOfOptions, err: string): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
        if (typeof ctrl.value === expectedType || ctrl.value == undefined) {
            return null;
        } else {
            return {invalid: err};
        }
    };
}

